import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { dateFormatLong } from "../utils/date"

const Archives = () => {
  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      sort: {fields: frontmatter___created, order: DESC}
      filter: {frontmatter: {published: {eq: true}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              created
              featuredImage {
                id
                uri {
                  publicURL
                  }
                  name {
                    relativePath
                  }
                }    
                featuredImageCreditLabel
                featuredImageCreditUri
                published
                season
                section
                slug
                tags
              }
            headings {
              id
            }
            html
            }
          }
        }
      }
      `)
      return (
        <Layout>
        <div className="container mx-auto p-2 sm:p-0 sm:grid sm:grid-cols-2 gap-4 mt-4">
            {data.allMarkdownRemark.edges.map(
              post =>(<div className="mb-4">
   <p className="font-serif text-2xl">
                    <Link to={post.node.frontmatter.slug}>
                        {post.node.frontmatter.title}
                    </Link>            
                </p>
                <p className="text-gray-500 font-serif text-xl">{dateFormatLong(new Date(post.node.frontmatter.created))}</p> 

              </div>
             
              ))}

        </div>

        </Layout>
      )
    }
          
export default Archives
